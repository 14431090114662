







import { Component, Mixins } from 'vue-property-decorator'
import Discord from '@/services/Connections/Discord'

@Component
export default class MoneyHandler extends Mixins(Discord) {}
